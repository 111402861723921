import React from "react";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import ChoiceMenu from "../../ui-components/Button/ChoiceMenu";

function SendButton({
  studentOrNot,
  gptModel,
  submitQuestionHandler,
  fontSize,
  size,
  questionText,
  tokenLeft,
  isLoading,
}) {
  const reachStudentLimit = tokenLeft <= 0 && studentOrNot;

  return (
    <Grid sx={{ width: "100%", display: "flex" }} >
      <Grid sx={{ width: "78%" }}>
        <Button
          id="sendButton"
          variant="contained"
          sx={{
            width: "100%",
            color: "white!important",
            lineHeight: 1, 
            borderRadius: "20px 0 0 20px", 
            pointerEvents:
              reachStudentLimit || questionText.length == 0 || isLoading 
                ? "none" //if any of the conditions is true, then pointerEvents is none
                : "inherit", //if all conditions are false, then pointerEvents is inherit
            background:
              reachStudentLimit || questionText.length == 0 || isLoading
              ? "#D3D3D3"
              //: gptModel != "4o"
              //? "#A730D0"
              //: "#218514",
             : (gptModel === "4" ? "#218514" : (gptModel === "3.5" ? "#A730D0" : "#0069b6")),
            height: "41px",
            boxShadow: "revert",
            paddingRight: "0px",
            "&:hover": {
              //background: gptModel == "3.5" ? "#c8a5f8" : "rgb(30 162 130)", 
              background: gptModel === "3.5" ? "#c8a5f8" : gptModel === "4" ? "rgb(30 162 130)" : "#0086e9",
            },
          }}
          onClick={submitQuestionHandler}
        >
          <Grid
            sx={{
              borderRight: "2px solid #ffffff57",
              width: "100%",
              fontWeight: "bold",
              fontSize: fontSize,
            }}
          >
            Send
            <br />
            {size.width > 768 && (gptModel === "3.5" ? "GPT-3.5" : gptModel === "4" ? "GPT-4" : "GPT-4o")}
          </Grid>
        </Button>
      </Grid>
      <Grid
        sx={{
          width: "20%",
          "&:hover": {
            backgroundColor: "unset !important",
            color: "unset !important",
          },
        }}
      >
        <ChoiceMenu blur={(questionText && !isLoading)} />
      </Grid>

    </Grid>
  );
}

export default React.memo(SendButton);