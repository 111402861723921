import React, { useContext, useEffect, useState, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { AuthenticatedTemplate } from "@azure/msal-react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ChatHistContext } from "../context";
import { StyledEngineProvider, CssVarsProvider } from "@mui/joy/styles";
import CopyContent from "../ui-components/Widget/CopyContent";
import EmailSending from "../ui-components/Widget/EmailSending";
import MonthlyTokenDisplay from "../ui-components/Widget/MonthlyTokenDisplay";
import { TimeOutAlert } from "../ui-components/TimeOutAlert";
import { UploadingAlert } from "../ui-components/UploadingAlert";
import TextareaDecorators from "../ui-components/Form/TextareaDecorators";
import EmptyChat from "../ui-components/ChatRoom/EmptyChat";
import Token from "../ui-components/Token";
import ChatRoom from "../ui-components/ChatRoom/ChatRoom";
import ChatHistoryDrawer from "../ui-components/ChatRoom/ChatHistoryDrawer";
import SendButton from "./component/SendButton";
import Tnc from "../ui-components/Widget/Tnc";
import Dialogue from "../ui-components/Widget/Dialogue";
import FilePreviewDialogue from "../ui-components/Widget/FilePreviewDialogue";
import Gpt4Warning from "../ui-components/Widget/Gpt4Warning";
import useWindowSize from "../hook/UseWindowSize";
import useSessionStorage from "../hook/useSessionStorage";
import { useSelector, useDispatch } from "react-redux";
import {
  setFileName,
  setFileBase64,
  setFileOrNot,
  setFileSize,
  setFileFormat,
  setExtractedText,
  setFileDialogOpen,
  setFileDialogContent,
  setSystemMessageTemplate,
} from "../action";

export function Home() {
  const size = useWindowSize();
  const {
    isLoading,
    isUploading,
    token,
    errorHappened,
    userQuestion,
    gptAnwser,
    fileUploadStatus,
    showTimeLeftWarning,
    submitQuestion,
    addToHist,
    chatHist,
    cleanFileContent
  } = useContext(ChatHistContext);
  const [gpt4Confirm, setGpt4Confirm] = useSessionStorage("Gpt4", "No"); 
  const [gpt4Dialogue, setGpt4Dialogue] = useState(false); 
  const [hoverTextArea, setHoverTextArea] = useState(false);
  const { gptModel, fontSize } = useSelector((state) => state.setting);
  const { studentOrNot, tokenLeft } = useSelector((state) => state.token);
  const { fileName, extractedText, fileDialogOpen, fileDialogContent, fileNameRemaining } =
    useSelector((state) => state.file);
  const navigate = useNavigate();
  const { instance } = useMsal();

  const [questionText, setQuestionText] = useState("");
  const [openTnc, setOpenTnc] = useState(false);
  const activeAccount = instance.getActiveAccount();
  const reachStudentLimit = tokenLeft <= 0 && studentOrNot;
  const dispatch = useDispatch();
  const ref = useRef(null);

  useEffect(() => {
    if (token == "" || token == null) {
      navigate("/");
    }
  }, [token, navigate]);

  useEffect(() => {
    if (!errorHappened && !isLoading) {
      setQuestionText("");
    }
  }, [errorHappened, isLoading]);

  const addHist = () => {
    addToHist();
    cleanFileContent();
  };
  const submitQuestionHandler = () => {
    //if ((gpt4Confirm == "Yes" && gptModel == "4") || gptModel != "4") {
    if (questionText == "" || reachStudentLimit || isLoading) {
      return false;
    }
    let now = new Date();
    let options = { timeZone: "Asia/Hong_Kong" };
    let hongKongTime = now.toLocaleString("en-GB", options);
    submitQuestion(questionText, hongKongTime, activeAccount.name);
    setQuestionText("");
    //}
    //console.clear();
    //if (gpt4Confirm == "No" && gptModel == "4") {
    //  //console.log('gpt4 confirm dialogue shown');
    //  setGpt4Dialogue(true);
    //}
  };

  const submitHandlerGpt4Dialogue = () => {
    let now = new Date();
    let options = { timeZone: "Asia/Hong_Kong" };
    let hongKongTime = now.toLocaleString("en-GB", options);
    //console.log(questionText);
    submitQuestion(questionText, hongKongTime, activeAccount.name);
    setQuestionText("");
    setGpt4Dialogue(false);
  };

  return (
    <AuthenticatedTemplate>
      <Gpt4Warning
        open={gpt4Dialogue}
        handleClose={setGpt4Dialogue}
        submitQuestion={submitHandlerGpt4Dialogue}
        setShowAgainGlobal={setGpt4Confirm}
      />
      <Grid
        ref={ref}
        sx={{
          paddingTop: "10px",
          background: "#f9f9f9",
          borderTop: "1px solid #D3D3D3",
          borderLeft: size.width < 500 ? "0px" : "1px solid #D3D3D3",
          borderRight: size.width < 500 ? "0px" : "1px solid #D3D3D3",
          marginTop: "0px",
          overflow: "auto",
          position: "relative",
          height:
            extractedText != "" && fileNameRemaining
              ? size.width < 500
                ? !showTimeLeftWarning && !isUploading
                  ? size.height - 250 + "px"
                  : size.height - 330 + "px"
                : !showTimeLeftWarning && !isUploading
                ? size.height - 270 + "px"
                : size.height - 330 + "px"
              : size.width < 500
              ? !showTimeLeftWarning && !isUploading
                ? size.height - 200 + "px"
                : size.height - 280 + "px"
              : !showTimeLeftWarning && !isUploading
              ? size.height - 220 + "px"
              : size.height - 280 + "px",
          width: "100%",
        }}
      >
        <Dialogue open={openTnc} handleClose={setOpenTnc} />
        <FilePreviewDialogue
          fileDialogOpen={fileDialogOpen}
          setFileDialogOpen={() => {
            dispatch(setFileDialogOpen(false));
          }}
          type={fileDialogContent.type}
          fileName={fileDialogContent.fileName}
          fileBase64={fileDialogContent.fileBase64}
          fileSize={fileDialogContent.fileSize}
          fontSize={fontSize}
          windowSize={size}
        />
        {chatHist && Object.keys(chatHist[0]).length == 0 ? (
          <Grid
            sx={{
              textAlign: "right",
              zIndex: "1000",
              display: "flex",
              justifyContent: "flex-end",
              background: "#F8F8F8",
              position: "fixed",
              width:
                ref.current &&
                ref.current.offsetWidth &&
                ref.current.offsetWidth - 10 + "px",
              "& div": {
                marginLeft: "15px",
              },
              "& div:last-child": {
                marginRight: "15px",
              },
            }}
          >
            {userQuestion.length > 0 && <EmailSending />}
            {userQuestion.length > 0 && <CopyContent />}
            <Tnc modelOpen={setOpenTnc} />
          </Grid>
        ) : (
          <ChatHistoryDrawer
            modelOpen={setOpenTnc}
            chatHist={chatHist}
            chatRoomWidth={
              ref.current &&
              ref.current.offsetWidth &&
              ref.current.offsetWidth + "px"
            }
          />
        )}
        {userQuestion.length == 0 && Object.keys(chatHist[0]).length == 0 ? (
          <EmptyChat />
        ) : (
          <ChatRoom
            userQuestion={userQuestion}
            gptAnwser={gptAnwser}
            setFileDialogOpen={setFileDialogOpen}
            setFileDialogContent={setFileDialogContent}
          />
        )}
      </Grid>
      {showTimeLeftWarning && <TimeOutAlert />}
      {isUploading && fileUploadStatus == "" && <UploadingAlert />}
      {fileUploadStatus != "" && (
        <UploadingAlert
          message={fileUploadStatus}
          backgroundColor={
            fileUploadStatus == "Document extracted successfully" || fileUploadStatus == "Image extracted successfully"
              ? "#1ea282"
              : "#FF5A50"
          }
        />
      )}
      <MonthlyTokenDisplay />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignContent: "stretch",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
          padding: fileName == "" || !fileNameRemaining ? "0px 10px 10px 10px" : "0px 10px 30px 10px",
          background: "white",
          flexDirection: "row",
          marginTop: size.width < 500 && !studentOrNot ? "-10px" : "inherit",
        }}
      >
        <Box
          sx={{
            width: size.width < 599 ? (!hoverTextArea ? "55%" : "65%") : "70%",
            position: "relative",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            padding: "0 20px",
          }}
        >
          <Grid
            sx={{
              display: size.width < 599 && !hoverTextArea ? "block" : "none",
            }}
          >
            <Button
              sx={{
                background: "white",
                color: "black",
                width: "100%",
                minWidth: "fit-content",
                "&:hover": { background: "white" },
              }}
              color={"primary"}
              onClick={addHist}
              disabled={userQuestion.length == 0 ? true : false}
            >
              <Grid
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  flexDirection: "row",
                  alignItems: "center",
                  color: userQuestion.length == 0 ? "#777" : "black",
                  cursor: userQuestion.length == 0 ? "no-drop" : "pointer",
                  height: "50px",
                  "& img": { width: "25px", height: "25px" },
                  "&:hover": { background: "white" },
                }}
              >
                <img src={"/newTopic.png"} className="App-logo" alt="logo" />{" "}
              </Grid>
            </Button>
          </Grid>

          <StyledEngineProvider injectFirst>
            <CssVarsProvider>
              <TextareaDecorators
                setHoverTextArea={setHoverTextArea}
                questionText={questionText}
                setQuestionText={setQuestionText}
                placeholder={
                  reachStudentLimit
                    ? "Reach the limit of available token. Please try again until token count is renewed"
                    : size.width > 768
                    ? "Type your query here. (Ctrl + Enter for new line)"
                    : "Type your query here."
                }
                reachStudentLimit={reachStudentLimit}
                screenWidth={size.width}
                //gpt4Confirm={gpt4Confirm}
                //gptModel={gptModel}
                //setGpt4Dialogue={setGpt4Dialogue}
              />
            </CssVarsProvider>
          </StyledEngineProvider>
        </Box>
        <Box
          sx={{
            background: "white",
            width: "30%",
            marginTop: size.width <= 620 ? "15px" : "inherit",
            marginBottom: size.width <= 620 ? "15px" : "inherit",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Grid
            sx={{
              width: size.width > 600 ? "100%" : "fit-content",
              display: size.width > 600 ? "block" : "none",
            }}
          >
            <Button
              sx={{
                background: "white",
                color: "black",
                width: "100%",
                "&:hover": { background: "white" },
              }}
              color={"primary"}
              onClick={addHist}
              disabled={userQuestion.length == 0 ? true : false}
            >
              <Grid
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  flexDirection: "row",
                  alignItems: "center",
                  color: userQuestion.length == 0 ? "#777" : "black",
                  cursor: userQuestion.length == 0 ? "no-drop" : "pointer",
                  height: "50px",
                  "& img": { width: "25px", height: "25px" },
                  "&:hover": { background: "white" },
                }}
              >
                <img src={"/newTopic.png"} className="App-logo" alt="logo" />{" "}
                {size.width > 900 && (
                  <p style={{ fontSize: fontSize }}>New topic</p>
                )}
              </Grid>
            </Button>
          </Grid>
          <SendButton
            studentOrNot={studentOrNot}
            tokenLeft={tokenLeft}
            gptModel={gptModel}
            submitQuestionHandler={submitQuestionHandler}
            fontSize={fontSize}
            size={size}
            questionText={questionText}
            isLoading={isLoading}
          />
        </Box>
        <Box
          sx={{
            background: "white",
            width: "100%",
            marginTop: "15px",
            display: "none",
          }}
        >
          <Token />
        </Box>
      </Box>
    </AuthenticatedTemplate>
  );
}
