import parse from "html-react-parser";

export function studentQuota(fontSize) {
  return parse(`<table id="token-info-table" style="width:100%" cellspacing="0" cellpadding="0">
    <tbody><tr id="token-info-table-header" style="background:#ECFCF8;fontWeight:bold;color:black;font-size:${
      fontSize.replace("px", "") * 1 - 2 + "px"
    }">
      <th>Model</th>
      <th></th>
      <th>Monthly quota</th>
    </tr>
    <tr>
      <td class="bold-col">GPT - 3.5</td>
      <td class="bold-col">4k context</td>
      <td rowspan="3">500,000 tokens<br/>≈ USD $1</td>
    </tr>
    <tr>
      <td class="bold-col">GPT - 4</td>
      <td class="bold-col">8k context</td>
    </tr>
    <tr>
      <td class="bold-col">GPT - 4o</td>
      <td class="bold-col">4k context</td>
    </tr>
  </tbody></table>`);
}

export function staffQuota(fontSize) {
  return parse(`<table id="token-info-table" style="width:100%" cellspacing="0" cellpadding="0">
    <tbody><tr id="token-info-table-header" style="background:#ECFCF8;fontWeight:bold;color:black;font-size:${
      fontSize.replace("px", "") * 1 - 2 + "px"
    }">
      <th>Model</th>
      <th></th>
      <th>Daily quota</th>
    </tr>
    <tr>
      <td class="bold-col">GPT - 3.5</td>
      <td class="bold-col">4k context</td>
      <td>Unlimited</td>
    </tr>
    <tr>
      <td class="bold-col">GPT - 4</td>
      <td class="bold-col">8k context</td>
      <td>60/day</td>
    </tr>
    <tr>
      <td class="bold-col">GPT - 4o</td>
      <td class="bold-col">4k context</td>
      <td>Unlimited</td>
    </tr>    
  </tbody></table>`);
}
