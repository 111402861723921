import * as React from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import styled from "@emotion/styled";
import { useSelector, useDispatch } from "react-redux";
import { toBase64 } from "../../utils/Tools";
import { toMB } from "../../utils/Tools";
const Icon = styled(AttachFileIcon)`
  cursor: pointer;
`;

const FileInput = styled.input`
  display: none;
`;

const Tooltip = ({ message, model }) => {
  let modelClass = "upload-tooltip-";
  if(model == "3.5")
    modelClass = modelClass + "35"; 
  else
    modelClass = modelClass + model;

  return (
      <div className={`upload-tooltip ${modelClass}`} id="upload-tooltip">
          <span className={"tooltiptext"}>{message}</span>
      </div>
  );
};

function Attachment({
  isLoading,
  setIsLoading,
  setFileName,
  setFileOrNot,
  setFileBase64,
  setDocumentID,
  setExtractedText,
  setFileFormat,
  setFileType,
  setFileSize,
  setFileUploadStatus,
  setIsUploading,
  setFileNameRemaining,
  cleanFileContent
}) {
  const dispatch = useDispatch();
  const { gptModel } = useSelector((state) => state.setting);
  const { studentOrNot } = useSelector((state) => state.token);
  React.useEffect(() => {
    async function attactmentFunction() {
      document.querySelector("#documentUpload").addEventListener('cancel', (e) => {
        document.querySelector("#upload-tooltip").style.visibility = "hidden";
      });
      document.querySelector("#documentUpload").onchange = async function () {
        //console.log(this.value)
        if (!this.files) {
          console.error(
            "This browser doesn't seem to support the `files` property of file inputs."
          );
        } else {
          let file = this.files.length > 0 && this.files[0];
          if (typeof file.size != "undefined") {
            if (file.size > 3000000 ) {
              setIsUploading(true);
              setFileUploadStatus("File size exceeded 3MB !");

            }else {
              cleanFileContent();
              let base64encode = await toBase64(file);
              dispatch(setFileName(this.value));
              if(base64encode.startsWith("data:image"))
                dispatch(setFileType("image"));
              else
                dispatch(setFileType("file"));
              dispatch(setFileBase64(base64encode));
              dispatch(setFileOrNot(true));
              dispatch(setFileSize(toMB(this.files[0].size)));
              dispatch(setFileFormat(this.value.replace(/[.](?=.*[.])/g, "_").split(".")[1]));
              dispatch(setFileNameRemaining(true));
            }
          }
        }
        this.value = "";
        document.querySelector("#upload-tooltip").style.visibility = "hidden";
      };
    }
    attactmentFunction();
  }, []);

  return (
    <div>
      {gptModel === "4o"}
      <Icon
        onClick={() => {
          if (!isLoading) {
            dispatch(setDocumentID(""));
            dispatch(setExtractedText(""));
            dispatch(setFileName(""));
            dispatch(setFileOrNot(false));
            dispatch(setFileBase64(""));
            dispatch(setFileSize(""));
            dispatch(setFileFormat(""));
            dispatch(setFileType(""));
            document.querySelector("#documentUpload").click();
            document.querySelector("#upload-tooltip").style.visibility = "visible";
          }
        }}
      />
      <Tooltip model={gptModel} message={gptModel === "4o" 
                          ? studentOrNot 
                            ? "Support image files (png, jpeg, gif, bmp)" // 4o && student
                            : "Support document files and image files (png, jpeg, gif, bmp)" // 4o && staff
                          : "Support document files" // not 4o
      }/>
      <FileInput
        type="file"
        accept={gptModel === "4o" 
          ? studentOrNot 
            ? "image/png,image/jpeg,image/gif,image/bmp"
            : "application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation,image/png,image/jpeg,image/gif,image/bmp" 
          : "application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation"
        }
        id="documentUpload"
      ></FileInput>
    </div>
  );
}

export default React.memo(Attachment);
