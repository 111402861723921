import React, { useContext, useState, useEffect } from "react";
import parse from "html-react-parser";
import { ReceiverMessage, SenderMessage } from "mui-chat-box";
import { Avatar, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import useWindowSize from "../../hook/UseWindowSize";
import { ChatHistContext } from "../../context";
import { useSelector } from "react-redux";
import Dotdotdot from "../Widget/Dotdotdot";
import { FileNameContainter } from "../Widget/FileNameContainter";
import { selectingThreeSuggestions } from "../../utils/selectingThreeSuggestions";

function SuggestionsItem({ suggest, setSuggestionToTextArea }) {
  return (
    <Grid
      onClick={() => {
        setSuggestionToTextArea(suggest);
      }}
      sx={{
        marginTop: "10px",
        background: "#f9f9f9!important", //grey
        border: "1px solid!important",
        borderRadius: "20px!important",
        fontSize: "12px!important",
        padding: "10px!important",
        marginLeft: "0px!important",
        width: "100% !important",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        "&:hover": { background: "#f1f1f1!important" }, //grey
      }}
    >
      <span style={{ width: "90%", maxWidth: "400px" }}>{suggest}</span>
      <span>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.76875 0.75L7.6335 2.871L12.2655 7.503H0V10.497H12.2655L7.6335 15.129L9.76875 17.25L18 9L9.76875 0.75Z"
            fill="#545454"
          />
        </svg>
      </span>
    </Grid>
  );
}

function ChatList({
  location = "history",
  questions,
  anwsers,
  setFileDialogOpen = null,
  setFileDialogContent = null,
}) {
  const size = useWindowSize();
  const { fontSize } = useSelector((state) => state.setting);
  const { isLoading, setSuggestionToTextArea } = useContext(ChatHistContext);

  const [suggestion, setSuggestion] = useState([]);

  useEffect(() => {
    setSuggestion(selectingThreeSuggestions());
  }, []);

  let gptAnswersListCounter = 0;
  const questionAnsList = questions.map((question, index) => {
    return Object.keys(question).length != 2 ? (
      <Grid
        key={index}
        className="block"
        sx={{
          maxWidth: "100%",
          border: "0px solid",
          position: "relative",
          overflowX: "hidden",
          "&::before": {
            position: "absolute",
            zIndex: "100",
            top: "25px",
            right: "55px",
            display: "inline-block",
            background: "#eff7ff", //user side 浅蓝色背景
            width: "25px",
            height: "25px",
            borderTop: "1px solid #f1f5fc", //浅浅灰
            borderRight: "1px solid #f1f5fc",
            content: "''",
            transform: "rotate(45deg)",
            WebkitTransform: "rotate(45deg)",
            MozTransform: "rotate(45deg)",
            msTransform: "rotate(45deg)",
            OTransform: "rotate(45deg)",
          },
          "&::after": {
            position: "absolute",
            zIndex: "100",
            top:
              isLoading && index == questions.length - 1
                ? "0"
                : anwsers[gptAnswersListCounter] &&
                  anwsers[gptAnswersListCounter].content &&
                  anwsers[gptAnswersListCounter].content.indexOf("apologize") >
                    -1 &&
                  location != "history"
                ? "45%"
                : "65%",
            opacity: isLoading && index == questions.length - 1 ? "0" : "1",
            bottom: isLoading && index == questions.length - 1 ? "0" : "35%",
            left: "65px",
            display: "inline-block",
            background: "white",
            width: "25px",
            height: "20px",
            borderTop: "1px solid white",
            borderRight: "1px solid white",
            content: "''",
            transform: "rotate(320deg)",
            WebkitTransform: "rotate(320deg)",
            MozTransform: "rotate(320deg)",
            msTransform: "rotate(320deg)",
            OTransform: "rotate(320deg)",
          },

          "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded": {
            boxShadow: "3px 5px 4px -3px rgba(0,0,0,0.09)!important",
            WebkitTransform: "3px 5px 4px -3px rgba(0,0,0,0.09)!important",
            MozTransform: "3px 5px 4px -3px rgba(0,0,0,0.09)!important",
          },
          "& .MuiGrid-root:nth-of-type(odd)": {
            "& div:nth-of-type(2)": {
              "& >div": {
                width:
                  size.width < 500
                    ? size.width < 360
                      ? "90%"
                      : "85%"
                    : "100%",
                float: "right",
              },
            },
          },
          "& .MuiGrid-root": {
            maxWidth: "100%",
            marginBottom: "5px",

            "& div": {
              borderRadius: "0px",

              "& > div": {
                background: "#eff7ff",
              },
              "& div:nth-of-type(1)": {
                padding: "0px!important",
                marginLeft: "10px!important",
                marginTop: "10px",
              },
              "& div:nth-of-type(2)": {
                padding: "0px!important",
                marginLeft: "20px!important",
                marginRight: "10px!important",
                marginTop: "12px",
              },
              "& div:nth-of-type(3)": {
                marginRight: "15px",
                paddingBottom: "5px",
              },
            },
          },
        }}
      >
        <SenderMessage
          key={index + "- send"}
          padding="0"
          sx={{ backgroundColor: "#d4e7f6!important" }} //淡蓝色
          avatar={
            <Avatar>
              <img
                src={"/User.png"}
                className="App-logo"
                alt="logo"
                style={{ width: "90%" }}
              />
            </Avatar>
          }
        >
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: fontSize,
              backgroundColor: "#eff7ff!important",
              marginBottom: "0px!important",
              color: "#66BFFF", //joecelyn li name's colour
              fontWeight: "bold",
            }}
          >
            <Grid>{question.name}</Grid>
          </Grid>

          <Divider sx={{ width: "95%", margin: "0 auto" }} />
          <Grid
            sx={{
              fontSize: fontSize,
              marginBottom: "0px!important",
            }}
          >
            {question.question.split("\n").map((str, index) => (
              <p style={{ margin: "0px" }} key={index}>
                {str}
              </p>
            ))}
          </Grid>
          {question.fileName && location != "history" && (
            <FileNameContainter
              fileName={question.fileName}
              fileSize={question.fileSize}
              fileFormat={question.fileFormat}
              fileBase64={question.fileBase64}
              location="dialogues"
              width={size.width}
              setFileDialogOpen={setFileDialogOpen}
              setFileDialogContent={setFileDialogContent}
            />
          )}
          <Grid
            sx={{
              fontSize: fontSize,
              textAlign: "right",
              color: "#D3D3D3", //user side, date and time
              fontSize: "12px",
            }}
          >
            {question.dateTime}
          </Grid>
        </SenderMessage>
        {anwsers.length > 0 && anwsers[gptAnswersListCounter] && (
          <ReceiverMessage
            height={"100px"}
            key={index + "- received"}
            sx={{
              "& .MuiGrid-root": {
                "& .MuiPaper-root": {
                  background: "white!important",
                },
              },
            }}
            avatar={
              <Avatar>
                <img
                  src={
                    anwsers[gptAnswersListCounter].name === "GPT-4" 
                      ? "/RobotGPT4.png" 
                      : (anwsers[gptAnswersListCounter].name === "GPT-4o" 
                      ? "/RobotGPT4O.png" 
                      : "/Robot.png")
                  }
                  className="App-logo"
                  alt="logo"
                  style={{ width: "100%" }}
                />
              </Avatar>
            }
          >
            <Grid
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                fontSize: fontSize,
                background: "white!important",
              }}
            >
              <Grid
                sx={{
                  fontSize: fontSize,
                  color:
                    anwsers[gptAnswersListCounter].name === "GPT-4o"
                      ? "#0069b6"
                      :(anwsers[gptAnswersListCounter].name === "GPT-4"
                      ?  "#218514" //GREEN
                      : "#A730D0"), //PURPLE
                  fontWeight: "bold",
                  background: "white!important",
                }}
              >
                {anwsers[gptAnswersListCounter].name === "GPT-4o"
                  ? "GPT-4o"
                  : anwsers[gptAnswersListCounter].name === "GPT-4"
                  ? "GPT-4"
                  : "GPT-3.5"}
              </Grid>
              {anwsers[gptAnswersListCounter].tokenUsed && (
                <Grid
                  sx={{
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                    fontWeight: "400",
                    fontSize: "0.7em",
                    lineHeight: "1.5",
                    letterSpacing: "0.00938em",
                    color: "#BDBDBD",
                    textAlign: "center",
                    background: "white!important",
                  }}
                >
                  {"(Tokens used " +
                    anwsers[gptAnswersListCounter].tokenUsed +
                    ")"}
                </Grid>
              )}
            </Grid>
            <Divider sx={{ width: "95%", margin: "0 auto" }} />
            <Grid
              sx={{
                fontSize: fontSize,
                whiteSpace: "pre-wrap",
                background: "white!important",
              }}
            >
              {anwsers[gptAnswersListCounter].content == "Loading..." ? (
                <Dotdotdot />
              ) : (
                parse(anwsers[gptAnswersListCounter].content)
              )}
            </Grid>
            <Grid
              sx={{
                fontSize: fontSize,
                backgroundColor: "white",
                textAlign: "right",
                color: "#D3D3D3",
                fontSize: "12px",
                background: "white!important",
              }}
            >
              {anwsers[gptAnswersListCounter].dateTime}
            </Grid>
            <Grid
              sx={{
                background: "#f9f9f9!important",
                float: "left",
                marginTop: "5px",
              }}
            >
              {anwsers &&
                anwsers[gptAnswersListCounter] &&
                anwsers[gptAnswersListCounter].content &&
                anwsers[gptAnswersListCounter].content.indexOf("apologize") >
                  -1 &&
                location == "chatRoom" && (
                  <>
                    <Grid sx={{ display: "none" }}>1</Grid>
                    <Grid sx={{ display: "none" }}>1</Grid>
                    {suggestion.map((item) => {
                      return (
                        <SuggestionsItem
                          suggest={item}
                          setSuggestionToTextArea={setSuggestionToTextArea}
                        />
                      );
                    })}
                  </>
                )}
            </Grid>

            <Grid sx={{ display: "none" }}>{gptAnswersListCounter++}</Grid>
          </ReceiverMessage>
        )}
      </Grid>
    ) : Object.keys(question).includes("endChatName") ? (
      <Grid key={index}>
        <Typography
          sx={{ fontSize: fontSize, textAlign: "center", color: "#BDBDBD;" }}
        >
          Chat ended {question.endChatTime}
        </Typography>
      </Grid>
    ) : (
      <Grid
        key={index}
        sx={{ fontSize: fontSize, textAlign: "center", color: "#BDBDBD;" }}
      >
        <Typography
          sx={{ fontSize: fontSize, textAlign: "center", color: "#BDBDBD;" }}
        >
          Chat started
        </Typography>
      </Grid>
    );
  });

  return questionAnsList;
}

export default React.memo(ChatList);
