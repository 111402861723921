import * as React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ChatHistContext } from "../context";
import { useSelector, useDispatch } from "react-redux";
import InputSlider from "./Form/InputSlider";
import SystemMessageTextArea from "./Form/SystemMessageTextArea";
import CloseIcon from "@mui/icons-material/Close";
import { useMsal } from "@azure/msal-react";
import useWindowSize from "../hook/UseWindowSize";
import { isAuthPopup } from "../utils/AuthTools";

import {
  setFontSize,
  setIsMenuOpend,
  setDefaultState,
  setResetCounter,
} from "../action";
//import SignInSignOutButton from "./Button/SignInSignOutButton";
import { msalConfig } from "../authConfig";
import { useNavigate } from "react-router-dom";

export default function SideBar({ open }) {
  const size = useWindowSize();
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const { setToken } = React.useContext(ChatHistContext);
  const { isMenuOpen, fontSize, gptModel } = useSelector(
    (state) => state.setting
  );
  const navigate = useNavigate();
  //const { tokenUsed } = React.useContext(ChatHistContext);

  const closingHandler = React.useCallback(() => {
    dispatch(setIsMenuOpend(false));
  }, []);

  const logoutRequest = {
    account: instance.getActiveAccount(),
    postLogoutRedirectUri: msalConfig.auth.redirectUri,
    //mainWindowRedirectUri: msalConfig.auth.redirectUri,
  };
  const handleLogout = () => {
    //console.log(isAuthPopup());
    if (!isAuthPopup()) {
      try {
        instance.logoutPopup(logoutRequest).then(() => {
          setToken("");
          window.location.reload();
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const buttons = [
    <Button
      key="one"
      sx={{
        fontSize: "15px",
        backgroundColor: fontSize == "15px" ? "rgb(30 162 130);" : "white",
        color: fontSize == "15px" ? "white" : "rgb(30 162 130);",
        "&:hover": {
          backgroundColor:
            fontSize == "15px" ? "rgb(92 189 165 / 65%)" : "auto",
        },
      }}
      onClick={() => {
        dispatch(setFontSize("15px"));
      }}
    >
      <b>Default</b>
    </Button>,
    <Button
      key="two"
      sx={{
        fontSize: "14px",
        backgroundColor: fontSize == "20px" ? "rgb(30 162 130)" : "white",
        color: fontSize == "20px" ? "white" : "rgb(30 162 130)",
        "&:hover": {
          backgroundColor:
            fontSize == "20px" ? "rgb(92 189 165 / 65%)" : "auto",
        },
      }}
      onClick={() => {
        dispatch(setFontSize("20px"));
      }}
    >
      Large
    </Button>,
    <Button
      key="three"
      sx={{
        fontSize: "14px",
        backgroundColor: fontSize == "24px" ? "rgb(30 162 130)" : "white",
        color: fontSize == "24px" ? "white" : "rgb(30 162 130)",
        "&:hover": {
          backgroundColor:
            fontSize == "24px" ? "rgb(92 189 165 / 65%)" : "auto",
        },
      }}
      onClick={() => {
        dispatch(setFontSize("24px"));
      }}
    >
      Huge
    </Button>,
  ];

  const list = (anchor) => (
    <Box
      sx={{
        width:
          anchor == "top" || anchor == "bottom"
            ? "auto"
            : size.width < 500
            ? size.width - 20 + "px"
            : 500,
        //padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: size.height + "px",
      }}
      role="presentation"
    >
      <Grid container sx={{ padding: "20px" }}>
        <Grid item xs={6} sx={{ fontSize: 25 }}>
          Settings
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ paddingTop: "5px", position: "relative" }}
          justify="flex-end"
        >
          <CloseIcon
            onClick={closingHandler}
            sx={{
              position: "absolute",
              right: 0,
            }}
          />
        </Grid>
      </Grid>
      {/*<Divider />*/}
      <Grid sx={{ height: size.height > 1000 ? "85%" : "unset" }}>
        <List
          sx={{
            padding: "30px",
            "& li": { marginBottom: "30px" },
            background: "#FCFCFC",
            height: "100%",
          }}
        >
          <ListItem disablePadding>
            <Grid sx={{ width: "100%" }}>
              <Grid>
                <Typography
                  component={"span"}
                  id="input-slider"
                  gutterBottom
                  sx={{ color: "rgb(30 162 130)", fontSize}}
                >
                  Font size
                </Typography>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  "& button": {
                    borderRadius: "30px",
                    border: " 1px solid",
                    width: "95%",
                  },
                }}
              >
                {buttons}
              </Grid>
            </Grid>
          </ListItem>
          <ListItem disablePadding>
            <SystemMessageTextArea />
          </ListItem>
          <br />
          <ListItem disablePadding>
            <InputSlider
              label={"Max response"}
              //range={gptModel != "4" ? "1:4000" : "1:8000"}
              range={gptModel === "4" ? "1:8000" : gptModel === "4o" ? "1:4096" : "1:4000"}
              info={
                "Give the model instructions about how it should behave and any context it should reference when generating a response. You can describe the assistant’s personality, tell it what it should and shouldn’t answer, and tell it how to format responses. There’s no token limit for this section, but it will be included with every API call, so it counts against the overall token limit."
              }
            />
          </ListItem>
          <ListItem disablePadding>
            <InputSlider
              label={"Temperature"}
              range={"0:1"}
              info={
                "Controls randomness. Lowering the temperature means that the model will produce more repetitive and deterministic responses. Increasing the temperature will result in more unexpected or creative responses. Try adjusting temperature or Top P but not both."
              }
            />
          </ListItem>
          <ListItem disablePadding>
            <InputSlider
              label={"Top P"}
              range={"0:1"}
              info={
                "Similar to temperature, this controls randomness but uses a different method. Lowering Top P will narrow the model’s token selection to likelier tokens. Increasing Top P will let the model choose from tokens with both high and low likelihood. Try adjusting temperature or Top P but not both."
              }
            />
          </ListItem>
          <ListItem disablePadding>
            <InputSlider
              label={"Past messages included"}
              range={"1:20"}
              info={
                "Select the number of past messages to include in each new query. This helps give the model context for new user queries. Setting this number to 10 will include 5 user queries and 5 system responses."
              }
            />
          </ListItem>
          {/*<ListItem
          disablePadding
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Grid>
            <Typography
              id="input-slider"
              gutterBottom
              sx={{ color: "rgb(30 162 130)" }}
              component={'span'}
            >
              Total Token Used:
            </Typography>
          </Grid>
          <Grid>
            <Typography
              id="input-slider"
              gutterBottom
              sx={{ color: "rgb(30 162 130)" }}
              component={'span'}
            >
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Grid>
                  <Typography sx={{ color: "rgb(30 162 130)", fontSize: "45px" }} component={'span'}>
                    {tokenUsed}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography sx={{ color: "#777" }} component={'span'}>/10000</Typography>
                </Grid>
              </Grid>
            </Typography>
          </Grid>
        </ListItem>*/}
        
          <ListItem disablePadding>
            <Grid
              item
              sx={{
                paddingTop: "5px",
                width: "100%",
              }}
              justify="flex-end"
              onClick={() => {
                dispatch(setDefaultState());
                dispatch(setResetCounter());
              }}
            >
              <Button
                sx={{
                  width: "100%",
                  borderRadius: "30px",
                  background: "white",
                  color: "white",
                  background: "rgb(30 162 130)",
                  paddingTop: "10px",
                  paddingBottom: "10px",

                  "&:hover": {
                    backgroundColor: "rgb(92 189 165 / 65%)",
                  },
                }}
              >
                Reset
              </Button>
            </Grid>
          </ListItem>
        </List>
      </Grid>
      {/*<Divider />*/}
      <Grid
        container
        sx={{
          padding: "20px",
          postion: "relative",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid item sx={{ width: "100%" }} justify="flex-end">
          <Button
            variant="contained"
            sx={{
              paddingTop: "10px",
              border: "1px solid",
              paddingBottom: "10px",
              background: "white",
              color: "rgb(30 162 130)",
              borderRadius: "30px",
              width: "100%",
              boxShadow: "0 0 0 0",
              "&:hover": {
                background: "#f9f9f9",
                boxShadow: "0 0 0 0",
              },
            }}
            onClick={() => {
              handleLogout();
            }}
          >
            Sign Out
          </Button>
        </Grid>

        <Grid
          item
          sx={{
            paddingTop: "5px",
            width: "40%",
          }}
          justify="flex-end"
          display="none"
          onClick={() => {
            dispatch(setIsMenuOpend(!isMenuOpen));
          }}
        >
          <Button
            sx={{
              width: "100%",
              borderRadus: "30px",
              background: "white",
              color: "rgb(30 162 130)",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor={"right"}
          open={isMenuOpen}
          onClose={() => {
            dispatch(setIsMenuOpend(!isMenuOpen));
          }}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
