export const fontSize = 'fontSize';
export const maxResponse = 'maxResponse';
export const temperature = 'temperature';
export const topP = 'topP';
export const passMessageIncluded = 'paseMessageIncluded';
export const isMenuOpen = 'isMenuOpen';
export const defaultState = 'defaultState';
export const reset = 'reset';
export const chatDrawer = 'chatDrawer';
export const gptModel = 'gptModel';
export const systemMessageContent = 'systemMessage';
export const systemMessageTemplate = 'systemMessageTemplate';
export const tokenAvailable='tokenAvailable';
export const studentOrNot='studentOrNot';
export const fileName = 'fileName';
export const fileOrNot = 'fileOrNot';
export const fileBase64 = 'fileBase64';
export const documentID = 'documentID';
export const extractedText = 'extractedText';
export const extractedStatus = 'extractedStatus';
export const fileFormat = 'fileFormat';
export const fileType = 'fileType';
export const fileSize = 'fileSize';
export const fileDialogOpen = 'fileDialogOpen';
export const fileDialogContent = 'fileDialogContent';
export const fileNameRemaining = 'fileNameRemaining';

export function setFontSize(size) {
  return {
    type: fontSize,
    payload: size,
  };
}

export function setMaxResponse(response) {
  return {
    type: maxResponse,
    payload: response,
  };
}

export function setTemperature(temp) {
  return {
    type: temperature,
    payload: temp,
  };
}

export function setTopP(p) {
  return {
    type: topP,
    payload: p,
  };
}

export function setPassMessageIncluded(msg) {
  return {
    type: passMessageIncluded,
    payload: msg,
  };
}

export function setIsMenuOpend(bool) {
  return {
    type: isMenuOpen,
    payload: bool,
  };
}

export function setDefaultState() {
  return {
    type: defaultState,
  };
}

export function setResetCounter() {
  return {
    type: reset,
  };
}

export function setIsDrawerOpend(bool) {
  return {
    type: chatDrawer,
    payload: bool,
  };
}

export function setGptModel(model) {
  return {
    type: gptModel,
    payload: model,
  };
}

export function setSystemMessage(content) {
  return {
    type: systemMessageContent,
    payload: content,
  };
}

export function setSystemMessageTemplate(content) {
  return {
    type: systemMessageTemplate,
    payload: content,
  };
}

export function setTokenAvailable(token){
  return {
    type: tokenAvailable,
    payload: token,
  };
}

export function setStudentOrNot(bool){
  return {
    type: studentOrNot,
    payload: bool,
  };
}

export function setFileName(name){
  return {
    type: fileName,
    payload: name,
  };
}

export function setFileOrNot(bool){
  return {
    type: fileOrNot,
    payload: bool,
  };
}

export function setFileBase64(base64){
  return {
    type: fileBase64,
    payload: base64,
  };
}

export function setDocumentID(id){
  return {
    type: documentID,
    payload: id,
  };
}

export function setExtractedText(text){
  return {
    type: extractedText,
    payload: text,
  };
}

export function setExtractedStatus(status){
  return {
    type: extractedStatus,
    payload: status,
  };
}

export function setFileSize(size){
  return {
    type: fileSize,
    payload: size,
  };
}

export function setFileFormat(format){
  return {
    type: fileFormat,
    payload: format,
  };
}

export function setFileType(type){
  return {
    type: fileType,
    payload: type,
  };
}

export function setFileDialogOpen(bool){
  return {
    type: fileDialogOpen,
    payload: bool,
  };
}

export function setFileDialogContent(type, fileName, fileSize, fileBase64){
  return {
    type: fileDialogContent,
    payload: {type, fileName, fileSize, fileBase64},
  };
}

export function setFileNameRemaining(bool){
  return {
    type: fileNameRemaining,
    payload: bool,
  };
}


