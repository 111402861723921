import React, {useEffect, useState, memo} from "react";
import styled from "@emotion/styled";
import {TextareaAutosize} from "@mui/base/TextareaAutosize";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import parse from "html-react-parser";
import {
  setSystemMessage,
  setSystemMessageTemplate,
  setDocumentID,
  setExtractedText,
  setFileName,
  setFileBase64,
  setFileFormat,
  setFileType,
  setFileSize,
  setFileOrNot,
} from "../../action";
import { systemMessageTemplate } from "../../utils/systemMessageTemplate";

function SystemMessageTextArea() {
  const [text, setText] = useState("");
  const [key, setKey] = useState("");
  const { fontSize } = useSelector((state) => state.setting);
  const dispatch = useDispatch();
  const { content, systemMessage, role } = useSelector(
    (state) => state.systemMessage
  );
  const { fileOrNot, extractedText} = useSelector((state) => state.file);

  const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const TextContainer = styled(Grid)(
    ({ theme }) => `    
    border: 2px solid rgb(30, 162, 130);
    padding: 10px;
    height: 120px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 10px;
    opacity:0.4
    `
   
  );

  const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
    width: 100%;
    font-family: "Roboto";
    font-size: ${fontSize};
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px; 
    border: 2px solid rgb(30 162 130);
    &:focus {
      border-color: ${blue[400]};
    }
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
  );

  const Header = styled(Typography)(
    ({ theme }) => `
    width: 100%;
    font-size: ${fontSize};
    marginBottom: -10px;
    color: rgb(30 162 130);
    display: flex;
    align-items: center;
  `
  );

  const Container = styled.div(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 100%;
  `
  );

  const InfoIcon = styled(InfoOutlinedIcon)`
    margin-top: 10px;
    width: 35px;
  `;

  const MenuItemCustom = styled(MenuItem)`
    font-size: ${fontSize};
    font-weight: 200 !important;
  `;

  useEffect(() => {
    dispatch(setSystemMessageTemplate(key));
  }, [key]);

  useEffect(() => {
    dispatch(setSystemMessage(text));
  }, [text]);

  useEffect(() => {
    setText(content);
    setKey(systemMessage);
    if (systemMessage == "") {
      setKey("Default");
      setText("You are an AI assistant that helps people find information.");
    }
    return () => {
      //console.log('textare unmount')
    };
  }, []);

  useEffect(() => {
    if (role === "") {
      setKey("Default");
      setText("You are an AI assistant that helps people find information.");
    }
  }, [role]);

  const handleTemplateChange = (event) => {
    setKey(event.target.value);
    let tempText;
    systemMessageTemplate.map((item) => {
      if (item.key === event.target.value) {
        tempText = item.value;
      }
    });
    setText(tempText);
    dispatch(setDocumentID(""));
    dispatch(setExtractedText(""));
    dispatch(setFileName(""));
    dispatch(setFileOrNot(false));
    dispatch(setFileBase64(""));
    dispatch(setFileSize(""));
    dispatch(setFileFormat(""));
    dispatch(setFileType(""));
  };

  return (
    <Container>
      <Header gutterBottom component={"span"}>
        {"System message"}
        <div className="tooltip">
          <InfoIcon sx={{ cursor: "pointer" }} />
          <span className="tooltiptext">
            {parse(
              `<span style="font-size:${
                fontSize.replace("px", "") * 1 - 2 + "px"
              }">Give the model instructions about how it should behave and any context it should reference when generating a response. You can describe the assistant’s personality, tell it what it should and shouldn’t answer, and tell it how to format responses. There’s no token limit for this section, but it will be included with every API call, so it counts against the overall token limit.</span>`
            )}
          </span>
        </div>
      </Header>
      <FormControl
        fullWidth
        sx={{
          //
          pointerEvents: fileOrNot ? "none" : "inherit",
          opacity: fileOrNot ? "0.4" : "1",
        }}
      >
        <Select
          value={key}
          onChange={handleTemplateChange}
          placeholder="system message template"
        >
          {systemMessageTemplate.map((item, index) => {
            return (
              <MenuItemCustom key={index} value={item.key}>
                {item.key}
              </MenuItemCustom>
            );
          })}
        </Select>
      </FormControl>
      <br />
      <StyledTextarea
        defaultValue={text}
        onBlur={(e) => {
          dispatch(setSystemMessage(e.target.value));
        }}
        minRows={4}
        maxRows={4}
        style={{
          pointerEvents: fileOrNot ? "none" : "inherit",
          display: fileOrNot ? "none" : "inherit",
        }}
      />
      <br />
      {/*<Header gutterBottom component={"span"} >
        {"Extract text from uploaded documents"}
        <div className="tooltip">
          <InfoIcon sx={{ cursor: "pointer" }} />
          <span className="tooltiptext">
            {parse(
              `<span style="font-size:${
                fontSize.replace("px", "") * 1 - 2 + "px"
              }">Azure AI Document Intelligence is a cloud-based Azure AI service that enables you to build intelligent document processing solutions. Massive amounts of data, spanning a wide variety of data types, are stored in forms and documents.</span>`
            )}
          </span>
        </div>
            </Header>*/}
      {fileOrNot && (
        <TextContainer>
          {`Answer questions truthfully based on the information in sources provided below. 
  If you cannot find the answer to a question based on the sources below, respond by saying “I apologize, but I am unable to provide an answer to your question, which is out of the scope of the document uploaded. Thank you!”
 ` +
            "  Sources: " +
            extractedText}
        </TextContainer>
      )}
    </Container>
  );
}

export default memo(SystemMessageTextArea);
