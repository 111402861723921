import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { ChatBox, ReceiverMessage, SenderMessage } from "mui-chat-box";
import { ChatHistContext } from "../../context";
import { setIsDrawerOpend } from "../../action";
import styled from "@emotion/styled";

import CopyContent from "../Widget/CopyContent";
import EmailSending from "../Widget/EmailSending";
import Tnc from "../Widget/Tnc";
import ChatList from "./ChatList";
import useWindowSize from "../../hook/UseWindowSize";

function ChatHistoryDrawer({ modelOpen, chatHist, chatRoomWidth }) {
  const [scrollTop, setScrollTop] = useState(0);

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor: "#FCFCFC", //white
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));
  const size = useWindowSize();
  const dispatch = useDispatch();

  const Contents = styled(ChatBox)(() => ({
    flexDirection: "row",
  }));

  const [expanded, setExpanded] = React.useState(false);
  const [expandedBool, setExpandedBool] = React.useState(true);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setExpandedBool((pre) => !pre);
  };

  React.useEffect(() => {
    dispatch(setIsDrawerOpend(expandedBool));
  }, [expandedBool]);

  React.useEffect(() => {
    setExpanded(false);
  }, [chatHist]);

  //useEffect(()=>{
    //console.log(scrollTop);
  //},[scrollTop])

  //const handleScroll = event => {
    //console.log("scrolling")
    //setScrollTop(event.currentTarget.scrollTop);
  //};

  
  return (
    <Grid
      container
      spacing={1}
      sx={{
        marginBottom: "20px",
        height: "fit-content",
        position: "relative",
        borderBottom: "1px solid #D3D3D3",
      }}
    >
      <Grid
        className={"header"}
        sx={{
          position: "fixed",
          right: size.width > 1200 ? "15%" : "5%",
          zIndex: "1000",
          marginTop: "50px",
          marginRight: "10px",
          display: "flex",
          justifyContent: size.height < 1200 ? "flex-end" : "space-evenly",
          alignItems: "center",
          width: chatRoomWidth,
          background: "#f9f9f9",
          width:"80%",
          "& div": {
            marginLeft: "15px",
          },
          "& div:last-child": {
            marginRight: "15px",
          },
        }}
      >
        <EmailSending />
        <CopyContent />
        <Tnc modelOpen={modelOpen} />
      </Grid>
      <Accordion
        expanded={expanded == "panel1"}
        onChange={handleChange("panel1")}
        sx={{ width: "100%", border: "0px", balor: "#f9f9f9" }}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography sx={{ color: "#777" }}>Chat History</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: "#f9f9f9" }}>
          <Contents>
            <ChatList
              key={"drawer"}
              questions={chatHist[0]}
              anwsers={chatHist[1]}
            />
          </Contents>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}

export default React.memo(ChatHistoryDrawer);
